import clsx from 'clsx';
import Image from 'next/image';
import { useRouter } from 'next/router';
import { useEffect, useState } from 'react';

import { getLocalizedProperty } from '../../lib/localization';
import { AdBannerType } from '../../types/homePageTypes';

interface AdBannerProps {
  size: 'leaderboard' | 'rectangle';
  category: string;
  adBanner: AdBannerType;
  className?: string;
}

const classesMap = {
  leaderboard: {
    styles: 'w-[970px] h-[90px] mx-auto',
    sizes: {
      width: '970',
      height: '90'
    }
  },
  rectangle: {
    styles: 'w-[336px] h-[280px]',
    sizes: {
      width: '336',
      height: '280'
    }
  }
};

const AdBanner = ({ size, category = 'category', adBanner, className }: AdBannerProps) => {
  const { locale } = useRouter();
  const [Impression, setImpression] = useState(false);

  const { utmCampaign, utmMedium, utmSource } = adBanner.utmParameters;
  const image = getLocalizedProperty(adBanner.images, locale)[size];

  useEffect(() => {
    const onLoad = () => {
      if (Impression === false) gaTrack('impression', 'transport');
    };
    if (document.readyState === 'complete') {
      onLoad();
    } else {
      window.addEventListener('load', onLoad);
      return () => window.removeEventListener('load', onLoad);
    }
  });

  const trackData = (transport: string) => {
    return {
      event_category: category,
      event_label: `partnerid:${adBanner.utmParameters.utmCampaign}`,
      ...(transport === 'beacon' && { transport: transport }),
      ...(transport === 'transport' && { non_interaction: true })
    };
  };

  const gaTrack = (action: string, transport: string) => {
    if (typeof window !== 'undefined' && window?.gtag !== undefined) {
      window?.gtag('event', action, trackData(transport));
      if (action === 'impression') setImpression(true);
    }
  };

  const trackClick = () => {
    gaTrack('click', 'beacon');
  };

  return (
    <div className={clsx('mx-auto', classesMap[size], className)}>
      <a
        href={`${adBanner.href}?utm_source=${utmSource}&utm_medium=${utmMedium}&utm_campaign=${utmCampaign}`}
        target="_blank"
        rel="noopener noreferrer"
        onClick={trackClick}>
        <Image
          alt="AdBanner"
          src={`https://images.ahotu.com/${image}?w=1920&f=webp`}
          className="object-cover"
          {...{
            ...classesMap[size].sizes,
            width: Number(classesMap[size].sizes.width), // Override width with number type
            height: Number(classesMap[size].sizes.height) // Override height with number type
          }}
        />
      </a>
    </div>
  );
};

export default AdBanner;
